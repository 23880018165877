.heading {
	color: white;
	max-width: 780px;
	font-size: 16px;
}

.headingText {
	color: white;
	font-size: 40px;
	font-weight: bolder;
}

.panel__section__background {
	background-color: hsl(187, 84%, 17%);
	color: white;
}

@media (max-width: 480px) {
	.headingText {
		font-size: 28px;
	}
}
