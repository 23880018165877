.card {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
	border: none;
	border-radius: 10px;
}

.heading {
	font-weight: bold;
	font-size: 40px;
	color: #074750;
}

.text {
	color: hsl(187, 84%, 17%);
	max-width: 780px;
	font-size: 16px;
}

@media (max-width: 480px) {
	.heading {
		font-size: 28px;
	}
}

@media (max-width: 768px) {
	.heading {
		text-align: center;
	}
	.text {
		text-align: center;
	}
	.button {
		margin: auto;
	}
	.image_container {
		top: -20px !important;
	}
}

.image_container {
	position: relative;
	max-width: 680px;
	width: 100%;
	height: 100%;
	overflow: hidden;
	top: -80px;
}

.button {
	width: 250px;
}
