.mailing_list {
	padding-top: 75px;
	padding-bottom: 75px;
	background-color: #074750;
	color: white;
}

.headingText {
	color: white;
	font-size: 40px;
	font-weight: bolder;
}

.buttonStyle {
	width: 250px;
	text-decoration: none;
}

@media (max-width: 480px) {
	.headingText {
		font-size: 28px;
	}
}
