.panel__section {
	padding-top: 75px;
	padding-bottom: 75px;
}

.heading {
	color: hsl(187, 84%, 17%);
	max-width: 780px;
	font-size: 16px;
}

.headingText {
	color: #074750;
	font-size: 40px;
	font-weight: bolder;
}

.panel__section__background {
	background-color: hsl(187, 84%, 17%);
	border-radius: 30px;
	max-width: 880px;
	color: white;
	box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5);
}

.panel__hoverEffect {
	color: inherit;
	transition: margin-top 0.2s;
}

.panel__hoverEffect:hover {
	background-color: #c1d11e;
	color: #074750;
	border-radius: 30px;
	margin-top: -20px;
}

.panel__hoverEffect:hover .svg__path {
	fill: #074750;
}

.getQuotesLink {
	display: none;
	color: #074750;

	margin-top: 10px;
}

.panel__hoverEffect:hover .getQuotesLink {
	display: inline-block;
}

@media (max-width: 480px) {
	.headingText {
		font-size: 28px;
	}
}
