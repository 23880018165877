.providers__heading {
	max-width: 350px;
}

.heading {
	font-weight: bold;
	font-size: 40px;
	color: #074750;
}

.text {
	color: hsl(187, 84%, 17%);
	max-width: 780px;
	font-size: 16px;
}
